.Toastify__toast-body {
  gap: 20px;
}

@media (max-width: 568px) {
  .Toastify__toast-container {
    position: fixed;
    left: auto !important;
    top: 14.615vw !important;
    right: -0.1% !important;
  }

  .Toastify__toast {
    height: 12.821vw !important;
    width: 100% !important;
    font-family: "Inter", sans-serif;
    font-size: 2.821vw !important;
  }

  .Toastify__toast-icon {
    width: 10.256vw !important;
    height: 10.256vw !important;
  }
  .Toastify__toast-body {
    gap: 0px;
    position: relative;
    // top: -5px;
  }
}
@media only screen and (min-width: 470px) and (max-width: 550px) {
  .Toastify__toast-body {
    gap: 0px;
    position: relative;
    top: -5px;
  }
}
