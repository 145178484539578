.register-outer-layout {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center
}

.register-card-layout {
    width: 380px;
    height: 320px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0px 12px 10px 1px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    padding: 2%;
}

.register-typography{
    width: 100%;
    height:25%;
    display: flex;
    flex-direction: column;
    gap: 12;
}

.register-form-box{
    width: 100%;
    height:75%
}

.register-form-layout{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 26px;
    
}

