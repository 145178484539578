@import "./assets/styles/theme.scss";
@import "./assets/styles/Styles.scss";

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  overscroll-behavior: none !important;
  touch-action: pan-x pan-y;
}

*, *::before, *::after {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  background: #F9F1DD;
  display: flex;
  width: 0.6vw;
  /* Width of the scrollbar */
  height: 300px;
  /* Set your desired height */
  overflow-y: scroll;
  /* Enable vertical scrolling */
  scrollbar-width: thin;
  border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #DEA829;
  /* Color of the scrollbar thumb */
  border-radius: 5px !important;
  /* Rounded corners for the thumb */
  height: 50px;
  width: 0.4vw;
}

@-moz-document url-prefix() {
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #DEA829 #F9F1DD;
  border-radius: 5px !important;
}

.custom-scrollbar:hover {
  scrollbar-color: #DEA829 #F9F1DD;
  border-radius: 5px !important;
}

.custom-month-popup {
  z-index: 9999 !important;
}
}


#scrollable-container{
  -webkit-overflow-scrolling: touch !important;
  overscroll-behavior: contain; 
  touch-action: pan-x pan-y;
  overflow-y: auto; 
  overflow-x: auto;
}
