.circular-webcam {
  width: 250px;
  height: 250px;
  position: relative;
  object-fit: cover;
  margin: 0 auto;
}

.capture-button {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: #dba72c;
  background-clip: content-box;
  padding: 40px;
  border: 40px solid #dba72c;
}

@media only screen and (max-width: 640px) {
  .circular-webcam {
    margin-top: 15%;
    width: 48.462vw;
    border-radius: 8px;
    height: 48.462vw;
  }
}
