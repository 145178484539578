.mainBox {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexChild {
    width: 54.8%;
    background-image: url("../../../assets/images/login_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 4%;
    border-bottom-left-radius: 4%;
}

.flexChild2 {
    height: 100%;
    display: flex;
    align-items: center;
}

.formBox {
    width: 88%;
}

.flexBox {
    border-radius: 4%;
    opacity: 0.2px;
    display: flex;
    margin: 0% auto;
}

#welcomeTypo {
    font-weight: 600;
    color: #CC981C;
    margin-bottom: 5%;
}

#subTypo {
    font-weight: 600;
    color: #252525;
}