// side bar css changes
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    top: 65px !important;
    // width: 14rem !important;
}

button {
    color: white ;
    font-weight: 700;
}

