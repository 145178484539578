@import "./assets/styles/theme.scss";

.NoSpinnerTextField.no-spinner input::-webkit-outer-spin-button,
.NoSpinnerTextField.no-spinner input::-webkit-inner-spin-button {
  display: none;
}

.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinner {
  -moz-appearance: textfield;
}

